// export const URL_LOGO_SEALABS = "https://raw.githubusercontent.com/sealabscr/public-assets/main/sealabs.cr/logo_sealabs.png"
export const URL_TEAM =
  'https://res.cloudinary.com/sealabs/image/upload/q_auto:eco/f_auto/v1709516609/caribbeanadventures.cr/caribbean_team_kdp9sk.webp';
export const URL_BUTTERFLY =
  'https://res.cloudinary.com/sealabs/image/upload/q_auto:eco/f_auto/v1709516642/caribbeanadventures.cr/butterfly_er04j7.png';
export const URL_PLANTS =
  'https://res.cloudinary.com/sealabs/image/upload/q_auto:eco/f_auto/v1709773962/caribbeanadventures.cr/section_page_leaves_be7kbd.png';
export const URL_TRIPADVISOR =
  'https://res.cloudinary.com/sealabs/image/upload/q_auto:eco/f_auto/v1713038584/caribbeanadventures.cr/tripadvisor-logo_orv0vv.png';
export const URL_FROG =
  'https://res.cloudinary.com/sealabs/image/upload/q_auto:eco/f_auto/v1712721294/caribbeanadventures.cr/frog_x5ffgb.png';
export const URL_NOTFOUND =
  'https://res.cloudinary.com/sealabs/image/upload/q_auto:eco/f_auto/v1712866414/caribbeanadventures.cr/404_sloth_ag7bwm.png';
