import { AccountCircle } from '@mui/icons-material';
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import fontsStyles from '../../styles/fontStyles';
import useStyles from './styles';

const ExperienceSmallCard = ({ username, img, location, stars, date, title, content, url }) => {
  const fontClasses = fontsStyles();
  const classes = useStyles();

  const starsArray = Array.from({ length: 5 }, (_, index) =>
    index < stars ? (
      <CircleIcon key={index} sx={{ fontSize: '15px' }} className={classes.circleIcon} />
    ) : (
      <CircleOutlinedIcon key={index} sx={{ fontSize: '15px' }} className={classes.circleIcon} />
    ),
  );

  return (
    <Link to={url} target='_blank' style={{ textDecoration: 'none' }}>
      <div className={classes.cardWrapper}>
        <div className={classes.cardSubWrapper}>
          <div className={classes.userWrapper}>
            {img ? (
              <img loading='lazy' src={img} alt='Profile Image' className={classes.userImage} />
            ) : (
              <AccountCircle className={classes.userImage} />
            )}
            <div className={classes.usernameWrapper}>
              <p className={clsx(fontClasses.p, classes.userName)}>{username}</p>
              <p className={clsx(fontClasses.p, classes.userLocation)}>{location}</p>
            </div>
          </div>
          <div>
            <div className={classes.starsWrapper}>
              <div>{starsArray}</div>
              <p className={clsx(fontClasses.p, classes.dateText)}>{date}</p>
            </div>
            <p className={clsx(fontClasses.p, classes.titleText)}>{title}</p>
            <p className={clsx(fontClasses.p, classes.reviewText)}>{content}</p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ExperienceSmallCard;
