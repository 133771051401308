import { Helmet } from 'react-helmet';

const Metatags = ({
  title = 'Caribbean Adventures',
  description = 'Explore unforgettable tours and experiences in Costa Rica.',
  canonical,
  robots = 'index, follow',
  author = 'Caribbean Adventures',
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta name='author' content={author} />
      {canonical && <link rel='canonical' href={canonical} />}
      {robots && <meta name='robots' content={robots} />}
    </Helmet>
  );
};

export default Metatags;
