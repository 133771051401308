import 'react-phone-input-2/lib/material.css';

import { useLazyQuery, useMutation } from '@apollo/client';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import {
  CircularProgress,
  Dialog,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
} from '@mui/material';
import clsx from 'clsx';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';

import { AmexSvg, MasterCardSvg, VisaSvg } from '../../assets/SvgLibrary';
import CustomButton from '../../components/CustomButton';
import Metatags from '../../components/MetaTags/metatags';
import { PATHS } from '../../constants';
import { deepLinkTo } from '../../helpers/DeepLinking';
import { formatCruisesByDate } from '../../helpers/interfaces/cruisesInterface';
import { formatTours } from '../../helpers/interfaces/tourInterface';
import { getPricesInfo } from '../../helpers/prices';
import { ADD_BOOKING, GET_PAYMENT_LINK } from '../../queries/booking';
import GET_CRUISES_BY_DATE from '../../queries/cruises';
import { GET_TOUR_BY_ID } from '../../queries/tours';
import fontsStyles from '../../styles/fontStyles';
import styles from './styles';

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const TourConfirmationPage = (props) => {
  const query = useQuery();
  const navigate = useNavigate();
  const classes = styles();
  const { t } = useTranslation();
  const fontClasses = fontsStyles();
  const [loadingInfo, setLoadingInfo] = useState(true);
  const [validateParams, setValidateParams] = useState();
  const [tourID, setTourId] = useState();
  const [date, setDate] = useState();
  const [quantity, setQuantity] = useState();
  const [paymentType, setPaymentType] = useState();
  const isDesktop = useMediaQuery({ query: '(min-width: 1100px)' });
  const [tourInfo, setTourInfo] = useState();
  const [cruiseByDate, setCruiseByDate] = useState([]);
  const { state } = useLocation();
  const onlinePayment = process.env.REACT_APP_ONLINE_PAYMENT === 'true';

  const [getCruiseDates, { data: cruisesDates }] = useLazyQuery(GET_CRUISES_BY_DATE, {
    variables: {
      filters: {
        date: {
          eq: date,
        },
      },
    },
  });

  const [getTourData, { data: tourData }] = useLazyQuery(GET_TOUR_BY_ID, {
    variables: {
      tourId: tourID,
    },
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    if (cruisesDates) {
      if (cruisesDates.cruiseDates.data.length > 0) {
        setCruiseByDate(formatCruisesByDate(cruisesDates).cruisesNameList);
      }
    }
  }, [cruisesDates]);

  useEffect(() => {
    if (tourData) {
      if (tourData.tour.data) {
        setTourInfo(formatTours(tourData));
      } else {
        navigate(PATHS.tourCatalogue);
      }
    }
  }, [tourData]);

  useEffect(() => {
    const queryTourId = query.get('tourId');
    const queryDate = query.get('date');
    const queryQuantity = query.get('quantity');
    const queryPayment = query.get('payment');
    if (queryTourId && queryDate && queryQuantity && queryPayment) {
      setTourId(queryTourId);
      setDate(dayjs(queryDate).format('YYYY-MM-DD'));
      setQuantity(queryQuantity);
      setPaymentType(queryPayment);
      setValidateParams(true);
    } else {
      navigate(PATHS.tourCatalogue);
    }
  }, [query]);

  useEffect(() => {
    if (!state) {
      if (tourID && quantity && date && paymentType) {
        getCruiseDates();
        // valida que el tour se un entero
        if (!isNaN(tourID)) {
          getTourData(); // Obtiene la infor del tour o lo redirecciona a la pagina de toursd
          // valida que la cantidad sea numerico y mayor a 1
          if (!isNaN(quantity) && parseInt(quantity) >= 1) {
            // Valida que la fecha se mayor a la actual + 1
            if (
              dayjs(query.get('date')).isSame(dayjs(new Date()), 'day') ||
              dayjs(query.get('date')).isAfter(dayjs(new Date()), 'day')
            ) {
              // Valida que el tipo de pago sea los indicados
              if (paymentType === 'later') {
                setLoadingInfo(false);
              } else if (paymentType === 'card') {
                if (onlinePayment) {
                  setLoadingInfo(false);
                } else {
                  // console.log('fail: payment');
                  navigate(`${PATHS.tour}/${tourID}`);
                }
              } else {
                // console.log('fail: payment');
                navigate(`${PATHS.tour}/${tourID}`);
              }
            } else {
              // console.log('fail: fecha');
              navigate(`${PATHS.tour}/${tourID}`);
            }
          } else {
            // console.log('fail: number');
            navigate(`${PATHS.tour}/${tourID}`);
          }
        } else {
          navigate(PATHS.tourCatalogue);
        }
      }
    } else {
      if (tourID && quantity && date && paymentType) {
        setTourInfo(state.tourInfo);
        setCruiseByDate(state.cruiseByDate);
        setPaymentType(state.paymentType);
        setLoadingInfo(false);
      }
    }
  }, [validateParams]);

  const LoadingStructure = () => {
    return (
      <div className={classes.headerWrapper}>
        <div className={classes.headerContentWrapper}>
          <Grid container spacing={4}>
            <Grid item lg={8} md={12} sm={12} xs={12}>
              <Skeleton variant='rounded' width={'100%'} height={isDesktop ? 400 : 200} />
            </Grid>
            <Grid item lg={4} md={12} sm={12} xs={12}>
              <Skeleton variant='rounded' width={'100%'} height={isDesktop ? 250 : 300} />
            </Grid>
          </Grid>
        </div>
      </div>
    );
  };

  const ReservationForm = () => {
    const [pricesInfo, setPricesInfo] = useState();

    return (
      <div className={classes.headerWrapper}>
        <div className={classes.headerContentWrapper}>
          <Grid container spacing={4}>
            <Grid item lg={8} md={12} sm={12} xs={12}>
              {isDesktop ? (
                <StepsBox
                  paymentType={paymentType}
                  priceInformation={pricesInfo}
                  setPricesInfoHandler={setPricesInfo}
                />
              ) : (
                <ReservationBox pricesInfo={pricesInfo} />
              )}
            </Grid>
            <Grid item lg={4} md={12} sm={12} xs={12}>
              {isDesktop ? (
                <ReservationBox pricesInfo={pricesInfo} />
              ) : (
                <StepsBox
                  paymentType={paymentType}
                  priceInformation={pricesInfo}
                  setPricesInfoHandler={setPricesInfo}
                />
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    );
  };

  const StepsBox = ({ paymentType, priceInformation, setPricesInfoHandler }) => {
    const [loadingComponent, setLoadingComponent] = useState(false);
    const [bookingID, setBookingId] = useState();
    const [fistName, setFirstName] = useState('');
    const [lastName, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [formattedPhone, setFormattedPhone] = useState('');
    const [cruiseTour, setCruiseTour] = useState(
      cruiseByDate.length > 0 ? cruiseByDate[0].id : null,
    );
    const [stepCount, setStepCount] = useState(0);
    const [cruiseModal, setCruiseModal] = useState(false);
    const [paymentMetohd, setPaymentMetohd] = useState(paymentType);
    const [disableButton, setDisabledButton] = useState(false);
    const [addBooking, { data: resultAddBooking, error: errorAddBooking }] =
      useMutation(ADD_BOOKING);
    const [getPaymentLink, { data: paymentLinkData, error: errorPaymentLink }] = useLazyQuery(
      GET_PAYMENT_LINK,
      {
        variables: {
          filters: {
            booking: {
              id: {
                eqi: bookingID,
              },
            },
          },
        },
      },
    );

    useEffect(() => {
      const getPrices = async () => {
        setPricesInfoHandler(null);
        setPricesInfoHandler(await getPricesInfo(tourID, quantity, paymentMetohd));
      };

      if (paymentMetohd) {
        getPrices();
      }
    }, [paymentMetohd]);

    const deepLink = (pagePath, sectionId) => {
      if (window.location.pathname === pagePath) {
        deepLinkTo(sectionId);
      } else {
        navigate(`${pagePath}#${sectionId}`);
      }
    };

    const handleChangePayment = (event) => {
      setPaymentMetohd(event.target.value);
    };

    const changeModalState = () => {
      setCruiseModal(!cruiseModal);
    };

    const previousStep = () => {
      setStepCount(stepCount - 1);
    };

    const nextStep = () => {
      setStepCount(stepCount + 1);
    };

    const cruiseHandleChange = (event) => {
      setCruiseTour(event.target.value);
    };

    const validateEmail = (email) => {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      return emailRegex.test(email);
    };

    const handlePhoneChange = (value, country, e, formattedValue) => {
      setPhone(value);
      setFormattedPhone(formattedValue);
    };

    useEffect(() => {
      setDisabledButton(
        fistName === '' || lastName === '' || email === '' || phone === '' || !validateEmail(email),
      );
    }, [fistName, lastName, email, phone]);

    useEffect(() => {
      if (resultAddBooking) {
        if (paymentMetohd === 'later') {
          navigate(
            `${PATHS.tourConfirmationSolution}?result=success&code=${resultAddBooking.createBooking.data.attributes.code}&tour=${resultAddBooking.createBooking.data.attributes.tour.data.id}`,
          );
          setLoadingComponent(false);
        } else {
          setBookingId(resultAddBooking.createBooking.data.id);
        }
      }
      if (errorAddBooking) {
        navigate(`${PATHS.tourConfirmationSolution}?result=fail&tour=${tourID}`);
        setLoadingComponent(false);
      }
    }, [resultAddBooking, errorAddBooking]);

    useEffect(() => {
      if (errorPaymentLink) {
        navigate(`${PATHS.tourConfirmationSolution}?result=fail&tour=${tourID}`);
        setLoadingComponent(false);
      }
    }, [errorPaymentLink]);

    useEffect(() => {
      if (bookingID) {
        getPaymentLink();
      }
    }, [bookingID]);

    useEffect(() => {
      if (paymentLinkData) {
        if (paymentLinkData.paymentLinks.data.length > 0) {
          window.location.href = paymentLinkData.paymentLinks.data[0].attributes.url;
        } else {
          navigate(`${PATHS.tourConfirmationSolution}?result=fail&tour=${tourID}`);
          setLoadingComponent(false);
        }
      }
    }, [paymentLinkData]);

    const completeBooking = () => {
      setLoadingComponent(true);
      addBooking({
        variables: {
          data: {
            booking_date: date,
            cruise_id: cruiseTour,
            payment_type: paymentMetohd === 'later' ? '2' : '1',
            quantity: parseInt(quantity),
            tour: tourID,
            sub_total: priceInformation.subtotal,
            service_fee: priceInformation.serviceFee,
            total: priceInformation.total,
            user_email: email,
            user_first_name: fistName,
            user_last_name: lastName,
            user_phone: formattedPhone,
          },
        },
      });
    };

    const CruiseModal = () => {
      return (
        <Dialog open={cruiseModal} maxWidth={'md'} onClose={changeModalState} disableScrollLock>
          <div className={classes.modalBox}>
            <div className={classes.stepButtonBox}>
              <IconButton aria-label='delete' size='large' onClick={changeModalState}>
                <CloseIcon sx={{ fontSize: '24px' }} className={classes.icon} />
              </IconButton>
            </div>
            <DirectionsBoatIcon sx={{ fontSize: '80px' }} className={classes.icon} />
            <p className={fontClasses.p}>{t('confirmationPage.cruiseInfo')}</p>
            <CustomButton
              label={t('confirmationPage.seasonCalendar')}
              onClick={() => {
                deepLink(PATHS.about, 'cruise-season');
              }}
            />
          </div>
        </Dialog>
      );
    };

    return (
      <div className={classes.stepperBox}>
        {loadingComponent ? (
          <div className={classes.loadingookingBox}>
            <CircularProgress />
            <p className={fontClasses.p}>{t('confirmationPage.loadingBooking')}</p>
          </div>
        ) : (
          <Stepper activeStep={stepCount} orientation='vertical'>
            <Step>
              <StepLabel>{t('confirmationPage.contactDetails')}</StepLabel>
              <StepContent>
                <div className={classes.stepContetBox}>
                  <p className={fontClasses.p}>{t('confirmationPage.contactDetailsText')}</p>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <p className={clsx(fontClasses.p, fontClasses.semiBold)}>
                        {t('confirmationPage.fistName')}
                      </p>
                      <TextField
                        id='bookingForm-name'
                        value={fistName}
                        onChange={(event) => {
                          setFirstName(event.target.value);
                        }}
                        variant='outlined'
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <p className={clsx(fontClasses.p, fontClasses.semiBold)}>
                        {t('confirmationPage.lastName')}
                      </p>
                      <TextField
                        id='bookingForm-name'
                        value={lastName}
                        onChange={(event) => {
                          setLastname(event.target.value);
                        }}
                        variant='outlined'
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <p className={clsx(fontClasses.p, fontClasses.semiBold)}>
                        {t('confirmationPage.email')}
                      </p>
                      <TextField
                        id='bookingForm-email'
                        value={email}
                        onChange={(event) => {
                          setEmail(event.target.value);
                        }}
                        variant='outlined'
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                      <p className={clsx(fontClasses.p, fontClasses.semiBold)}>
                        {t('confirmationPage.phone')}
                      </p>
                      <div className={classes.phoneInput}>
                        <PhoneInput
                          country={'us'}
                          inputClass={classes.phoneInput}
                          specialLabel
                          value={phone}
                          onChange={handlePhoneChange}
                        />
                      </div>
                    </Grid>
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                      <p className={clsx(fontClasses.p, fontClasses.semiBold)}>
                        {t('confirmationPage.cruise')}
                      </p>
                      <Select
                        id='bookingForm-cruise'
                        value={cruiseTour}
                        onChange={cruiseHandleChange}
                        displayEmpty
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                        fullWidth
                      >
                        {cruiseByDate.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                        <MenuItem value={null}>{t('confirmationPage.other')}</MenuItem>
                      </Select>
                      <div className={classes.stepButtonBox}>
                        <span
                          className={clsx(fontClasses.p, classes.cantFindText)}
                          onClick={changeModalState}
                        >
                          {t('confirmationPage.cantFind')}
                        </span>
                      </div>
                      <CruiseModal />
                    </Grid>
                  </Grid>
                  <div className={classes.stepButtonBox}>
                    <CustomButton
                      label={t('confirmationPage.next')}
                      onClick={nextStep}
                      icon={<ArrowForwardIcon />}
                      disabled={disableButton}
                    />
                  </div>
                </div>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>{t('confirmationPage.paymentMethod')}</StepLabel>
              <StepContent>
                <div className={classes.stepContetBox}>
                  <p className={fontClasses.p}>{t('confirmationPage.paymentMethodText')}</p>
                  <RadioGroup
                    value={paymentMetohd}
                    onChange={handleChangePayment}
                    name='payment-buttons-group'
                  >
                    <Grid container spacing={2}>
                      {onlinePayment && (
                        <Grid item lg={6} md={12} sm={12} xs={12}>
                          <div className={classes.radioInputBox}>
                            <FormControlLabel
                              value='card'
                              control={<Radio />} // disabled en caso de que este deshabilitado
                              label={t('confirmationPage.creditDebit')}
                            />
                            <div className={classes.paymentBrands}>
                              <VisaSvg />
                              <MasterCardSvg />
                              <AmexSvg />
                            </div>
                          </div>
                        </Grid>
                      )}
                      <Grid item lg={6} md={12} sm={12} xs={12}>
                        <div className={classes.radioInputBox}>
                          <FormControlLabel
                            value='later'
                            control={<Radio />}
                            label={t('confirmationPage.payLater')}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </RadioGroup>
                  <div className={classes.stepButtonBox}>
                    <CustomButton
                      label={t('confirmationPage.back')}
                      onClick={previousStep}
                      secondary
                    />
                    <CustomButton
                      label={t('confirmationPage.completeBooking')}
                      onClick={completeBooking}
                      icon={<ArrowForwardIcon />}
                      disabled={loadingComponent || !priceInformation}
                    />
                  </div>
                  <div className={classes.stepButtonBox}>
                    <p className={clsx(fontClasses.p, classes.termsAndConditionsTexts)}>
                      {t('confirmationPage.termsAndConditions')}
                    </p>
                  </div>
                </div>
              </StepContent>
            </Step>
          </Stepper>
        )}
      </div>
    );
  };

  const ReservationBox = ({ pricesInfo }) => {
    return (
      <div className={classes.reservationBox}>
        <img
          src={tourInfo.images[0].url}
          alt={tourInfo.images[0].alternativeText}
          className={classes.reservationImage}
        />
        <div className={classes.reservationContentBox}>
          <h5 className={clsx(fontClasses.h5, classes.bookTitle)}>{tourInfo.name}</h5>
          <div>
            <div className={classes.iconTextBox}>
              <PeopleOutlineOutlinedIcon sx={{ fontSize: '18px' }} className={classes.icon} />
              <p className={fontClasses.p}>
                {quantity}{' '}
                {quantity === 1 ? t('confirmationPage.person') : t('confirmationPage.people')}
              </p>
            </div>
            <div className={classes.iconTextBox}>
              <EventOutlinedIcon sx={{ fontSize: '18px' }} className={classes.icon} />
              <p className={fontClasses.p}>{date}</p>
            </div>
          </div>
          <div className={classes.priceBox}>
            <div>
              <p className={clsx(fontClasses.p, classes.priceText)}>
                {t('confirmationPage.tourPrice')}:
              </p>
              <p className={clsx(fontClasses.p, classes.priceText)}>
                {t('confirmationPage.subTotal')}:
              </p>
              <p className={clsx(fontClasses.p, classes.priceText)}>
                {t('confirmationPage.costPerService')}:
              </p>
              <p className={clsx(fontClasses.p, classes.priceText, fontClasses.semiBold)}>
                {t('confirmationPage.total')}:
              </p>
            </div>
            {pricesInfo && (
              <div>
                <p className={clsx(fontClasses.p, classes.priceText)}>${pricesInfo.price}</p>
                <p className={clsx(fontClasses.p, classes.priceText)}>${pricesInfo.subtotal}</p>
                <p className={clsx(fontClasses.p, classes.priceText)}>${pricesInfo.serviceFee}</p>
                <p className={clsx(fontClasses.p, classes.priceText, fontClasses.semiBold)}>
                  ${pricesInfo.total}
                </p>
              </div>
            )}
            {!pricesInfo && (
              <div>
                <Skeleton
                  variant='rounded'
                  width={'70px'}
                  height={16}
                  sx={{ marginBottom: '5px' }}
                />
                <Skeleton
                  variant='rounded'
                  width={'70px'}
                  height={16}
                  sx={{ marginBottom: '5px' }}
                />
                <Skeleton
                  variant='rounded'
                  width={'70px'}
                  height={16}
                  sx={{ marginBottom: '5px' }}
                />
                <Skeleton
                  variant='rounded'
                  width={'70px'}
                  height={16}
                  sx={{ marginBottom: '5px' }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Metatags
        title='Caribbean Adventures | Booking'
        description='Complete you booking and enjoy your trip.'
        robots='noindex, follow'
      />
      {!loadingInfo && tourInfo ? <ReservationForm /> : <LoadingStructure />}
    </div>
  );
};

export default TourConfirmationPage;
