import { useQuery } from '@apollo/client';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import {
  ArrowForwardIos,
  ChatBubbleOutline,
  CloseOutlined,
  HomeOutlined,
  ImportExport,
  Rule,
  SearchOutlined,
  SupportAgent,
  TodayOutlined,
} from '@mui/icons-material';
import { MenuItem, Select, Skeleton, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import CustomCheckbox from '../../components/CustomCheckbox/CustomCheckbox';
import CustomSlider from '../../components/CustomSlider/CustomSlider';
import Metatags from '../../components/MetaTags/metatags';
import TourCard from '../../components/TourCard/tourCard';
import { PATHS } from '../../constants';
import { getUrlQueryParams } from '../../helpers/QueryParameters';
import { GET_FILTERED_TOURS } from '../../queries/tours';
import breakpoints from '../../theme/breakpoints';
import styles from './styles';

const TourCataloguePage = () => {
  const { t } = useTranslation();

  const durationFiltersData = [
    { label: t('toursCatalogue.up1'), filter: { duration: { lte: 1 } } },
    { label: t('toursCatalogue.up1to5'), filter: { duration: { gte: 1, lte: 5 } } },
    { label: t('toursCatalogue.up5toDay'), filter: { duration: { gte: 5, lte: 24 } } },
    { label: t('toursCatalogue.upday'), filter: { duration: { gt: 24 } } },
  ];

  const categoryFiltersData = [
    { label: t('toursCatalogue.tourFilter'), filter: { category: { name: { eq: 'Tours' } } } },
    {
      label: t('toursCatalogue.packagesFilter'),
      filter: { category: { name: { eq: 'Packages' } } },
    },
    {
      label: t('toursCatalogue.transportantionFilter'),
      filter: { category: { name: { eq: 'Transportation only' } } },
    },
  ];

  const languageFiltersData = [
    { label: t('toursCatalogue.filterSpanish'), filter: { languages: { containsi: 'ES' } } },
    { label: t('toursCatalogue.filterEnglish'), filter: { languages: { containsi: 'EN' } } },
  ];

  const sortData = [
    { label: t('toursCatalogue.sortFilterName'), sort: 'name:asc' },
    { label: t('toursCatalogue.sortFilterPrice'), sort: 'price:asc' },
    { label: t('toursCatalogue.sortFilterDuration'), sort: 'duration:asc' },
  ];

  const classes = styles();
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [currencyFilter, setCurrencyFilter] = useState([0, 250]);
  const [orderFilter, setOrderFilter] = useState(sortData[0]);
  const [durationFilter, setDurationFilter] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [languageFilter, setLanguageFilter] = useState([]);
  const parameters = getUrlQueryParams();
  const searchQuery = parameters.get('search');
  const navigate = useNavigate();

  const generateFilterQuery = useCallback(() => {
    const filtersList = [];
    if (searchQuery) {
      filtersList.push({ name: { containsi: searchQuery } });
    }
    filtersList.push({ price: { gte: currencyFilter[0], lte: currencyFilter[1] } });
    filtersList.push({
      or: languageFilter.map((i) => {
        return languageFiltersData[i]?.filter || {};
      }),
    });
    filtersList.push({
      or: durationFilter.map((i) => {
        return durationFiltersData[i]?.filter || {};
      }),
    });
    filtersList.push({
      or: categoryFilter.map((i) => {
        return categoryFiltersData[i]?.filter || {};
      }),
    });
    return filtersList;
  }, [currencyFilter, searchQuery, languageFilter, categoryFilter, durationFilter]);

  const {
    data: filteredTours,
    loading: isLoading,
    error: isError,
  } = useQuery(GET_FILTERED_TOURS, {
    variables: {
      filters: { and: generateFilterQuery() },
      sortOrder: orderFilter.sort,
    },
  });

  const areFiltersClean = useMemo(
    () =>
      currencyFilter.toString() === [0, 250].toString() &&
      durationFilter.length === 0 &&
      categoryFilter.length === 0 &&
      languageFilter.length === 0,
    [categoryFilter, currencyFilter, languageFilter, durationFilter],
  );

  const filteredTourQuantity = useMemo(
    () => filteredTours?.tours?.data?.length | (0)[filteredTours],
  );

  const themeBreakpoints = createBreakpoints(breakpoints);
  const isLargeBp = useMediaQuery(themeBreakpoints.up('lg'), {
    noSsr: true,
  });

  useEffect(() => {
    if (isLargeBp && mobileFiltersOpen) {
      closeMobileFilters();
    }
  }, [isLargeBp]);

  const disableBodyScroll = (disableFlag) => {
    if (disableFlag) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  };

  useEffect(() => {
    if (!window.location.hash) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      });
    }
  }, []);

  const deepLink = (pagePath, sectionId) => {
    if (window.location.pathname === pagePath) {
      deepLink(sectionId);
    } else {
      navigate(`${pagePath}#${sectionId}`);
    }
  };

  const openMobileFilters = () => {
    setMobileFiltersOpen(true);
    disableBodyScroll(true);
  };

  const closeMobileFilters = () => {
    setMobileFiltersOpen(false);
    disableBodyScroll(false);
  };

  const cleanFilters = () => {
    setCurrencyFilter([0, 250]);
    setDurationFilter([]);
    setCategoryFilter([]);
    setLanguageFilter([]);
  };

  const handleCurrencyFilterChange = (event, newValue) => {
    setCurrencyFilter(newValue);
  };

  const handleDurationFilterChange = (value) => {
    const updatedFilter = structuredClone(durationFilter);
    if (durationFilter.includes(value)) {
      const foundIndex = durationFilter.indexOf(value);
      updatedFilter.splice(foundIndex, 1);
      setDurationFilter(updatedFilter);
    } else {
      updatedFilter.push(value);
      setDurationFilter(updatedFilter);
    }
  };

  const handleCategoryFilterChange = (value) => {
    const updatedFilter = structuredClone(categoryFilter);
    if (categoryFilter.includes(value)) {
      const foundIndex = categoryFilter.indexOf(value);
      updatedFilter.splice(foundIndex, 1);
      setCategoryFilter(updatedFilter);
    } else {
      updatedFilter.push(value);
      setCategoryFilter(updatedFilter);
    }
  };

  const handleLanguageFilterChange = (value) => {
    const updatedFilter = structuredClone(languageFilter);
    if (languageFilter.includes(value)) {
      const foundIndex = languageFilter.indexOf(value);
      updatedFilter.splice(foundIndex, 1);
      setLanguageFilter(updatedFilter);
    } else {
      updatedFilter.push(value);
      setLanguageFilter(updatedFilter);
    }
  };

  const handleSearchFilterClean = () => {
    if (searchQuery) {
      navigate(PATHS.tourCatalogue);
    }
  };

  const TourFilters = ({
    durationFilter,
    categoryFilter,
    languageFilter,
    currencyFilterHandler,
    durationFilterHandler,
    categoryFilterHandler,
    languageFilterHandler,
  }) => {
    const classes = styles();
    return (
      <>
        <div className={classes.filterGroup}>
          <p className={classes.filterTitle}>{t('toursCatalogue.titleFilterPrice')}</p>
          <CustomSlider
            min={0}
            max={250}
            currentValues={currencyFilter}
            changeFunction={currencyFilterHandler}
          />
        </div>
        <div className={classes.filterGroup}>
          <p className={classes.filterTitle}>{t('toursCatalogue.titleFilterDuration')}</p>
          {durationFiltersData.map((filter, index) => {
            return (
              <CustomCheckbox
                key={`${filter.label}-checkbox-key`}
                className={classes.filterCheckbox}
                label={filter.label}
                value={durationFilter.includes(index)}
                changeFunction={() => durationFilterHandler(index)}
              />
            );
          })}
        </div>
        <div className={classes.filterGroup}>
          <p className={classes.filterTitle}>{t('toursCatalogue.titleFilterCategory')}</p>
          {categoryFiltersData.map((filter, index) => {
            return (
              <CustomCheckbox
                key={`${filter.label}-checkbox-key`}
                className={classes.filterCheckbox}
                label={filter.label}
                value={categoryFilter.includes(index)}
                changeFunction={() => categoryFilterHandler(index)}
              />
            );
          })}
        </div>
        <div className={classes.filterGroup}>
          <p className={classes.filterTitle}>{t('toursCatalogue.titleFilterLanguages')}</p>
          {languageFiltersData.map((filter, index) => {
            return (
              <CustomCheckbox
                key={`${filter.label}-checkbox-key`}
                className={classes.filterCheckbox}
                label={filter.label}
                value={languageFilter.includes(index)}
                changeFunction={() => languageFilterHandler(index)}
              />
            );
          })}
        </div>
      </>
    );
  };

  return (
    <div className={classes.toursWrapper}>
      <Metatags
        title='Caribbean Adventures | Tours'
        description='Find your next experience.'
        canonical='https://caribbeanadventures.cr/tours'
        robots='index, follow'
      />
      <div className={classes.toursContentWrapper}>
        <div className={classes.toursHeader}>
          <div className={classes.headerContainer}>
            <div>
              <div className={`${classes.headerNavigation} ${classes.desktopOnly}`}>
                <HomeOutlined sx={{ fontSize: '20px' }} className={classes.homeSvg} />
                <Link
                  className={`${classes.headerNavigationText} ${classes.headerNavigationButton}`}
                  to={PATHS.home}
                >
                  {t('toursCatalogue.homeBreadcumbs')}
                </Link>
                <ArrowForwardIos sx={{ fontSize: '12px' }} className={classes.caretSvg} />
                <p className={`${classes.headerNavigationText}`}>
                  {t('toursCatalogue.toursBreadcumbs')}
                </p>
              </div>
              <h1 className={classes.headerMainTitle}>Puerto Limón, Costa Rica</h1>
              {searchQuery && (
                <div className={classes.searchContainer}>
                  <p className={classes.headerMainSubtitle}>
                    {t('toursCatalogue.search')} <span>{searchQuery}</span>
                  </p>
                  <button
                    className={classes.clearSearchButton}
                    onClick={() => handleSearchFilterClean()}
                  >
                    Clear search
                  </button>
                </div>
              )}
            </div>
            <div className={`${classes.headerLinksContainer} ${classes.desktopOnly}`}>
              <button
                className={`${classes.headerLink}`}
                onClick={() => {
                  deepLink(PATHS.about, 'frequently-asked');
                }}
              >
                <ChatBubbleOutline sx={{ fontSize: '12px' }} className={classes.headerLinkSvg} />
                {t('toursCatalogue.buttonQuestions')}
              </button>
              <button
                className={`${classes.headerLink}`}
                onClick={() => {
                  deepLink(PATHS.about, 'cruise-season');
                }}
              >
                <TodayOutlined sx={{ fontSize: '12px' }} className={classes.headerLinkSvg} />
                {t('toursCatalogue.buttonCruise')}
              </button>
              <Link className={`${classes.headerLink}`} to={PATHS.contact}>
                <SupportAgent sx={{ fontSize: '12px' }} className={classes.headerLinkSvg} />
                {t('toursCatalogue.buttonBook')}
              </Link>
            </div>
          </div>
          <div className={classes.headerDivider} />
        </div>
        <div className={classes.tourViewerContainer}>
          <div className={classes.tourViewerFilters}>
            {mobileFiltersOpen && (
              <>
                <div className={classes.mobileFiltersShadow}></div>
                <div className={classes.mobileFiltersContainer}>
                  <div className={classes.mobileFiltersHead}>
                    <p className={classes.filterTitle}>Filters</p>
                    <button
                      className={classes.closeFiltersBtn}
                      onClick={() => closeMobileFilters()}
                    >
                      <CloseOutlined sx={{ fontSize: '24px' }} className={classes.closeSvg} />
                    </button>
                  </div>
                  <div className={classes.mobileFilters}>
                    <TourFilters
                      categoryFilter={categoryFilter}
                      durationFilter={durationFilter}
                      languageFilter={languageFilter}
                      currencyFilterHandler={handleCurrencyFilterChange}
                      durationFilterHandler={handleDurationFilterChange}
                      categoryFilterHandler={handleCategoryFilterChange}
                      languageFilterHandler={handleLanguageFilterChange}
                    />
                  </div>
                  <button
                    className={classes.applyFiltersButton}
                    onClick={() => closeMobileFilters()}
                  >
                    {t('toursCatalogue.buttonApply')}
                  </button>
                </div>
              </>
            )}
            {isLargeBp && (
              <div className={classes.desktopFilters}>
                <TourFilters
                  categoryFilter={categoryFilter}
                  durationFilter={durationFilter}
                  languageFilter={languageFilter}
                  currencyFilterHandler={handleCurrencyFilterChange}
                  durationFilterHandler={handleDurationFilterChange}
                  categoryFilterHandler={handleCategoryFilterChange}
                  languageFilterHandler={handleLanguageFilterChange}
                />
              </div>
            )}
          </div>
          <div className={classes.tourViewerCards}>
            <div className={classes.filterSection}>
              {isLoading || isError ? (
                <Skeleton variant='text' className={classes.availableToursSkeleton} />
              ) : (
                <p className={classes.filterMatchText}>
                  {`${filteredTourQuantity} tours available`}
                </p>
              )}
              <button
                className={`${classes.filterButton} ${classes.mobileOnly}`}
                onClick={() => openMobileFilters()}
              >
                <Rule sx={{ fontSize: '18px' }} className={classes.filterSvg} /> Filters
              </button>
              <div className={`${classes.filterButtonGroup} ${classes.desktopOnly}`}>
                {!areFiltersClean && (
                  <button className={`${classes.filterButton}`} onClick={() => cleanFilters()}>
                    <CloseOutlined sx={{ fontSize: '18px' }} className={classes.filterSvg} /> Clear
                    {t('toursCatalogue.buttonFilters')}
                  </button>
                )}
                <Select
                  className={`${classes.filterButton} ${classes.customSelect}`}
                  value={orderFilter.label}
                  onChange={(e) => setOrderFilter(e.target.value)}
                  displayEmpty
                  renderValue={(value) => (
                    <>
                      <ImportExport sx={{ fontSize: '18px' }} className={classes.filterSvg} />{' '}
                      {t('toursCatalogue.buttonFilter')}
                      <span>{value}</span>
                    </>
                  )}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                >
                  {sortData.map((elem) => {
                    return (
                      <MenuItem key={`tours-sort-${elem.label}`} value={elem}>
                        {elem.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
            </div>
            {isLoading || isError ? (
              <Grid container className={classes.customCardsGrid}>
                {[...Array(4)].map((__, index) => (
                  <Grid key={`${index}-card-skel`} item xs={12} sm={12} md={6} lg={12}>
                    <Skeleton variant='rectangular' className={classes.tourCardSkeleton} />
                  </Grid>
                ))}
              </Grid>
            ) : filteredTourQuantity !== 0 ? (
              <Grid container className={classes.customCardsGrid}>
                {filteredTours &&
                  filteredTours.tours.data.map((tour) => {
                    const tourData = tour.attributes;
                    return (
                      <Grid key={`tour-cat-${tour.id}`} item xs={12} sm={12} md={6} lg={12}>
                        <TourCard
                          name={tourData.name}
                          img={
                            tourData.images.data[0]?.attributes?.url ||
                            'https://res.cloudinary.com/sealabs/image/upload/q_auto:eco/f_auto/v1714449271/caribbeanadventures.cr/default_tour.png'
                          }
                          to={`${PATHS.tour}/${tour.id}`}
                          tag={tourData.tag}
                          price={`$${tourData.price}`}
                          hours={`${tourData.duration} ${tourData.duration === 1 ? 'hour' : 'hours'}`}
                          group={
                            tourData.min_capacity
                              ? `${tourData.min_capacity} ${tourData.min_capacity === 1 ? 'person' : 'people'}`
                              : undefined
                          }
                          languagues={tourData.languages.join(' - ')}
                          description={tourData.overview}
                          alt={tourData.images.data[0]?.attributes.alternativeText}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
            ) : (
              <div className={classes.noResultsContainer}>
                <SearchOutlined sx={{ fontSize: '50px' }} className={classes.noResultsIcon} />
                <div className={classes.noResultsSubContainer}>
                  <p className={classes.noResultsMainText}>
                    {t('toursCatalogue.titleNotFoundTours')}
                  </p>
                  <p className={classes.noResultsSecondaryText}>
                    {t('toursCatalogue.parragraphNotFoundTours')}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TourCataloguePage;
