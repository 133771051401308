import 'dayjs/locale/es';
import 'dayjs/locale/en';

import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet'; // Changed import here
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';

import DocsLoader from './components/DocsLoader/docsLoader.js';
import Footer from './components/Footer/footer.js';
import Navbar from './components/Navbar/navbar.js';
import WhatsappWidget from './components/WhatsappWidget/index';
import { PATHS } from './constants/index';
import About from './pages/about/about.js';
import Contact from './pages/contact/contact.js';
import Home from './pages/home/home.js';
import NotFoundPage from './pages/notFound/notFound.js';
import Tour from './pages/tour/tour.js';
import TourCatalogue from './pages/tourCatalogue/tourCatalogue.js';
import TourConfirmation from './pages/tourConfirmation/tourConfirmation.js';
import TourConfirmationSolution from './pages/tourConfirmationSolution/tourConfirmationSolution.js';
import getTheme from './theme/index.js';

const App = () => {
  const theme = getTheme();
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

  const httpLink = new HttpLink({ uri: process.env.REACT_APP_STRAPI_GRAPHQL });
  const whatsappPhoneNumber = process.env.REACT_APP_WHATSAPP_PHONE_NUMBER;

  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        authorization: `Bearer ${process.env.REACT_APP_STRAPI_ACCESS_TOKEN}`,
      },
    });
    return forward(operation);
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  const Layout = ({ children }) => {
    const location = useLocation();
    const excludedRoutes = [PATHS.docs];
    const shouldHideLayout = excludedRoutes.includes(`/${location.pathname.split('/')[1]}`);

    return (
      <>
        {!shouldHideLayout && <Navbar />}
        {!shouldHideLayout && <WhatsappWidget number={whatsappPhoneNumber} />}
        {children}
        {!shouldHideLayout && <Footer />}
      </>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Router>
            <Helmet>
              <meta name='robots' content='index, follow' />
              <meta name='author' content='Caribbean Adventures' />
              <meta name='description' content='Explore amazing tours in the Caribbean.' />
            </Helmet>
            <Layout>
              <Routes>
                <Route path={PATHS.home} element={<Home />} />
                <Route path={PATHS.about} element={<About />} />
                <Route path={PATHS.tourCatalogue} element={<TourCatalogue />} />
                <Route path={`${PATHS.tour}/:id`} element={<Tour />} />
                <Route path={PATHS.tourConfirmation} element={<TourConfirmation />} />
                <Route path={PATHS.contact} element={<Contact />} />
                <Route
                  path={PATHS.tourConfirmationSolution}
                  element={<TourConfirmationSolution />}
                />
                <Route path={`${PATHS.docs}/:doc`} element={<DocsLoader />} />
                <Route path={PATHS.notFound} element={<NotFoundPage />} />
              </Routes>
            </Layout>
          </Router>
        </LocalizationProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
};

export default App;
