import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import fontsStyles from '../../styles/fontStyles';
import useStyles from './styles';

const TourSmallCard = ({
  name,
  img,
  to,
  description,
  price,
  tag,
  hours,
  group,
  languagues,
  alt,
}) => {
  const fontClasses = fontsStyles();
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.cardWrapper}>
      <Link to={to} className={classes.linkWrapper}>
        <div className={classes.cardSubWrapper}>
          <div className={classes.tourImageBox}>
            <img loading='lazy' src={img} alt={alt} className={classes.tourImage} />
          </div>
          <div className={classes.infoTourBox}>
            <div className={classes.infoTour}>
              <div>
                <h4 className={clsx(fontClasses.p, classes.titleTour)}>{name}</h4>
                <div className={classes.tourDetailsBox}>
                  {hours && (
                    <div className={classes.descriptionIconBox}>
                      <AccessTimeIcon
                        sx={{ fontSize: '18px' }}
                        className={classes.descriptionIcon}
                      />
                      <p className={clsx(fontClasses.p, classes.descriptionText)}>{hours}</p>
                    </div>
                  )}
                  {group && (
                    <div className={classes.descriptionIconBox}>
                      <PeopleOutlineOutlinedIcon
                        sx={{ fontSize: '18px' }}
                        className={classes.descriptionIcon}
                      />
                      <p className={clsx(fontClasses.p, classes.descriptionText)}>{group}</p>
                    </div>
                  )}
                  {languagues && (
                    <div className={classes.descriptionIconBox}>
                      <PublicOutlinedIcon
                        sx={{ fontSize: '18px' }}
                        className={classes.descriptionIcon}
                      />
                      <p className={clsx(fontClasses.p, classes.descriptionText)}>{languagues}</p>
                    </div>
                  )}
                </div>
              </div>
              {tag && (
                <div>
                  <span className={clsx(fontClasses.p, classes.tagText)}>{tag}</span>
                </div>
              )}
              <p
                className={clsx(
                  fontClasses.p,
                  classes.descriptionText,
                  !tag ? classes.descriptionTextOverflow : classes.descriptionTextOverflowWithTag,
                )}
              >
                {description}
              </p>
            </div>
            <div className={classes.priceTourBox}>
              <p className={clsx(fontClasses.p, classes.priceTour)}>{t('tourCard.priceFrom')}</p>
              <p className={clsx(fontClasses.p, classes.priceTour)}>
                <span className={classes.titleTourBig}>{price}</span>
              </p>
              <p className={clsx(fontClasses.p, classes.priceTour)}>{t('tourCard.pricePerson')}</p>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default TourSmallCard;
