import CloseIcon from '@mui/icons-material/Close';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import logo from '../../assets/logo.svg';
import { COLORS } from '../../constants';
import fontsStyles from '../../styles/fontStyles';
import CustomButton from '../CustomButton';
import useStyles from './styles';

const WhatsappWidget = ({ number }) => {
  const classes = useStyles();
  const fontClasses = fontsStyles();
  const { t } = useTranslation();

  const isMobile = function isMobile() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
      return true;
    return false;
  };

  const sendMessage = (number) => {
    let apilink = 'http://';
    apilink += isMobile() ? 'api' : 'web';
    apilink +=
      '.whatsapp.com/send?phone=' +
      number +
      '&text=' +
      encodeURI(t('whatsappComponent.message')) +
      '&app_absent=0';

    window.open(apilink);
  };

  const [show, setShow] = useState(false);

  return (
    <Box>
      <Grow in={show}>
        <Box className={classes.cardBox}>
          <Box className={classes.cardContentBox}>
            <button className={classes.widgetCloseButton} onClick={() => setShow(false)}>
              <CloseIcon style={{ fontSize: '20px', color: COLORS.gray }} />
            </button>
            <img loading='lazy' className={classes.widgetImage} src={logo} alt='logo' />
            <Box>
              <p className={clsx(fontClasses.p, classes.widgetTitle)}>
                {t('whatsappComponent.title')}
              </p>
              <p className={clsx(fontClasses.p, classes.widgetMessage)}>
                {t('whatsappComponent.text')}
              </p>
            </Box>
            <CustomButton
              label={t('whatsappComponent.button')}
              tertiary
              onClick={() => sendMessage(number)}
            />
          </Box>
        </Box>
      </Grow>
      <Box className={classes.widgetMainButton} onClick={() => setShow(!show)}>
        <WhatsAppIcon style={{ fontSize: '35px' }} />
      </Box>
    </Box>
  );
};

export default WhatsappWidget;
